<template>
  <div
    class="d-flex justify-center align-center"
    style="height: 100vh;"
  >
    <v-progress-circular
      indeterminate
      color="primary"
    ></v-progress-circular>
  </div>
</template>

<script>
// eslint-disable-next-line import/no-cycle
import { authenticate } from '@/views/globalHelpers'
// eslint-disable-next-line import/no-cycle
import axios from '@axios'
import { useRouter } from '@core/utils'
import { onMounted, ref } from '@vue/composition-api'

export default {
  name: 'Ott',
  setup() {
    const error = ref(null)
    const { router, route } = useRouter()

    onMounted(() => {
      const { oneTimeToken } = route.value.query
      const nextPageName = route.value.query.nextPage || 'manage-events'

      if (!oneTimeToken) {
        router.push({ name: 'auth-login' })

        return
      }

      const params = {}
      Object.keys(route.value.query).forEach(key => {
        if (key.startsWith('param_')) {
          const paramName = key.replace('param_', '')
          params[paramName] = route.value.query[key]
        }
      })

      const nextPageObj = {
        name: nextPageName,
        params,
      }

      const authPromise = axios.post('ott/validate', { token: oneTimeToken })

      const errorHandler = () => {
        router.push({ name: 'auth-login' })
      }

      authenticate(authPromise, errorHandler, nextPageObj)
    })

    return {
      error,
    }
  },
}
</script>
